<template>
    <div>
        <div class="popup__header">
            <div class="title">Выдать вознаграждение</div>
            <div class="text-color-white-trans text-bold mt4">{{ salary.user.post }}: {{ salary.user.firstName }}
                {{ salary.user.lastName }} ({{ salary.user.char.toUpperCase() }}{{ salary.user.personalNum }})
            </div>
        </div>


        <div class="popup__content give-salary">
            <div class="give-salary__list">
                <div class="give-salary__item" v-for="request in requests">
                    <div class="name">
                        {{ request.name }}
                    </div>
                    <div class="square-meters">
                        {{ request.area }} м<sup>2</sup>
                    </div>
                    <div class="sum">
                        {{ number_format(request.sum) }} <span class="rouble">{{
                            $store.getters['options/setRouble']
                        }}</span>
                    </div>
                </div>
                <div class="total text-bold">
                    <div class="df df-space-between mt8">
                        <div class="txt">
                            ИТОГО:
                        </div>
                        <div class="square-meters">
                            {{ allArea }} м<sup>2</sup>
                        </div>
                        <div class="sum">
                            {{ allSum }} <span class="rouble">{{ $store.getters['options/setRouble'] }}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="full mt48">
              <textarea-field :class="'full'"
                              name="case"
                              v-model="form.text.value"
                              :error-text="form.text.errorText"
                              :label="'Обстоятельства передачи денег'"
                              :is-error="form.text.error"
                              @focus="removeError(form.text)"
                              @input="removeError(form.text)"
                              :autoFocus="true"
                              placeholder="Например, «Выдана в офисе» или «Перевел на карту»"
                              :add-style="{minHeight: 48 + 'px'}"></textarea-field>
            </div>
            <div class="df df-space-between mt40">
                <submit-btn tag="button" @click="applySalary()" :type="form.btnType">
                    Подтвердить
                </submit-btn>

                <span class="text-right text-size-xs text-color-grey text-italic">
                    Указывайте обстоятельства передачи денег максимально<br>
                    подробно, чтобы можно было вспомнить, как было дело...
                </span>
            </div>
        </div>
    </div>
</template>

<script>
import {mapState} from 'vuex'
import Tooltip from '@/components/Tooltip.vue';
import SubmitBtn from '@/components/forms/SubmitBtn.vue';
import InputStyled from '@/components/forms/InputStyled.vue';
import TextareaField from '@/components/forms/TextareaField.vue';
import {number_format} from '@/helpers/formatted.js';

export default {
    name: "GiveReward",
    components: {
        Tooltip,
        SubmitBtn,
        InputStyled,
        TextareaField,
    },
    props: {
        salary: {
            default() {
                return 0
            }
        },
        month: {
            default() {
                return 0
            }
        },
    },
    mounted() {
        this.$nextTick(() => {
            setTimeout(() => {
                this.fetchData();
            }, 500);
        });
    },
    methods: {
        number_format: function (v) {
            return number_format(v, 0, '', ' ')
        },
        removeError(form) {
            form.error = false;
        },
        fetchData() {
            this.$nextTick(() => {
                this.$store.dispatch('salary/getModalData', {
                    type: 'modalGiveReward',
                    user: this.salary.user.id,
                    date: this.salary.date,
                    id: this.salary.id,
                }).then((response) => {
                    this.requests = response.result.salary.requests;
                    this.allArea = response.result.salary.allArea;
                    this.allSum = response.result.salary.allSum;
                });
            });
        },
        applySalary() {
            this.$nextTick(() => {
                if (this.form.text.value) {
                    this.form.btnType = 'load';
                    this.$store.dispatch('salary/applySalary', {
                        type: 'applySalaryReward',
                        user: this.salary.user.id,
                        date: this.salary.date,
                        comment: this.form.text.value,
                        id: this.salary.id,
                    }).then((response) => {
                        this.form.btnType = 'ok';
                        this.$store.commit('salary/changeTableRow', {
                            type: 'apply',
                            salary: this.salary,
                            completed: response.result.completed
                        });
                        this.$store.commit('popups/close', {name: this.popups.giveReward.name});
                        this.$emit('givedSalary');
                    });
                } else {
                    this.form.text.error = true;
                }
            });
        },
    },
    data: function () {
        return {
            form: {
                btnType: 'normal',
                text: {
                    value: '',
                    error: false,
                    repeatError: false,
                },
            },
            requests: {},
            allSum: 0,
            allArea: 0,
        }
    },
    computed: {
        ...mapState({
            popups: state => state.popups.items,
            salaries: state => state.salary.items,
            options: state => state.options,
            loadModalBtnLoad: state => state.loadModalBtnLoad,
        }),
    },
}
</script>

<style scoped>

</style>
