<template>
    <div class="content" :class="{'content--no-sub':user.groupCode == 'B'}">
        <div class="main-title">
            <div class="main-title__left">
                <div class="h1">{{ pageTitle }}</div>
            </div>

            <div class="main-title__right">
                <span class="i i-info mr4"></span>
                <span class="t">{{ pageDesc }}</span>
            </div>
        </div>

        <section class="container">

            <!-- Здесь пока ничего нет -->
            <nothing-block v-if="!load && !salaries.length"/>

            <div v-if="load" class="salaries">
                <div class="salaries__month --ghost" :class="{'opened': this.pageCode !== 'archive', '--buh': user.groupCode == 'B'}">
                    <div class="salaries__month-header">
                        <div class="text-size-ml text-bold text-color-white title">
                            <span></span>
                        </div>
                        <div class="header-elem object">
							<div class="header-elem__inner txt">
								<div class="i ico-object"></div>
	                            <span></span>
							</div>
                        </div>
                        <div class="header-elem square-meters">
							<div class="header-elem__inner txt">
	                            <div class="i ico-square-meters"></div>
	                            <span></span>
							</div>
                        </div>
						<div class="header-elem official-sum" v-if="user.groupCode != 'B'">
							<div class="header-elem__inner txt">
	                            <div class="i ico-official"></div>
	                            <span></span>
							</div>
                        </div>
                        <div class="header-elem sum">
							<div class="header-elem__inner txt">
	                            <div class="i ico-sum"></div>
	                            <span></span>
							</div>
                        </div>
                    </div>

                    <div class="salaries__month-list">
                        <div class="salaries__month-item" :key="'sm1'+i" v-for="i in 20">
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span v-if="user.groupCode != 'B'"></span>
                            <span></span>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else class="salaries">
                <div class="salaries__month" :class="{'opened': this.pageCode !== 'archive' || openedRows[month.tableCode], '--buh': user.groupCode == 'B'}" v-for="month in salaries" :key="'sm2'+month">
                    <div class="salaries__month-header">
                        <div class="text-size-ml text-bold text-color-white title">
                            {{ month.monthName }} {{ month.year }}
                        </div>
                        <div class="header-elem object">
							<div class="header-elem__inner txt">
								<div class="i ico-object"></div>
	                            {{ month.allFlats }}
							</div>
                        </div>
                        <div class="header-elem square-meters">
							<div class="header-elem__inner txt">
	                            <div class="i ico-square-meters"></div>
	                            {{ number_format(month.allArea) }} м<sup>2</sup>
							</div>
                        </div>
						<div class="header-elem official-sum" v-if="user.groupCode != 'B'">
                            <span class="header-elem__inner txt">
                                <div class="i ico-official"></div>
                                {{ month.allSumOfficial }} &nbsp;<span class="rouble">{{
                                    $store.getters['options/setRouble']
                                }}</span>
                            </span>
                        </div>
                        <div class="header-elem sum">
                            <span class="header-elem__inner txt">
                                <div class="i ico-sum"></div>
                                {{ month.allSum }} &nbsp;<span class="rouble">{{
                                    $store.getters['options/setRouble']
                                }}</span>
                            </span>
                        </div>
                        <div class="btn" :class="{'btn--trans': true, 'btn--preloader': loadRows[month.tableCode]}" @click="open(month.tableCode)" v-if="pageCode === 'archive'">
                            <div class="chevron"></div>
                            <span v-if="!openedRows[month.tableCode]">открыть</span>
                            <span v-else>закрыть</span>
                        </div>
                    </div>

                    <div class="salaries__month-list">
                        <div v-if="loadRows[month.tableCode]" class="salaries__month-list" >
                            <div class="salaries__month-item" v-for="i in 1" :key="'ms3'+i">
                                <span>Загрузка...</span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                        </div>
                        <div v-else-if="(typeof(this.archRows[month.tableCode]) !== 'undefined')" class="salaries__month-item" v-for="salary in this.archRows[month.tableCode]" :key="'ms3'+salary">
                            <div class="member-block">
                                <div :class="salary.user.char" class="member">
                                    {{ salary.user.char }}{{ salary.user.personalNum }}
                                </div>
                                <div class="txt">
                                    {{ salary.user.firstName }}
                                    {{ salary.user.lastName }}
                                </div>
                            </div>
                            <div class="objects">
                                {{ salary.flats }}
                            </div>
                            <div class="square-meters">
                                {{ salary.area }} м<sup>2</sup>
                            </div>
                            <div class="official-sum" v-if="user.groupCode != 'B'">
                                <template v-if="salary.official">
                                {{ salary.official.sum }} <span class="rouble">{{ $store.getters['options/setRouble'] }}</span>
                                <tooltip
                                    :direction="'bottom-center-direction'"
                                    :is-btn-close="false"
                                    :isChangeablePos="false"
                                    :add-class-wrap="'paid-tooltip'"
                                    :add-style="{width: 264 + 'px'}">
                                    <template v-slot:tooltipBtn>
                                        <span class="i i-ok7 i--green"></span>
                                    </template>

                                    <template v-slot:tooltipContent>
                                        <div class="text-size-m">
                                            {{salary.official.completedComment}}
                                        </div>
                                        <div class="text-size-s text-color-grey mt8">
                                            {{salary.official.completedDate}}
                                        </div>
                                        <div class="text-size-s text-color-grey mt4">
                                            {{salary.official.completedUser.name}}
                                        </div>
                                        <div class="text-size-s text-color-grey">
                                            {{salary.official.completedUser.post}}
                                        </div>
                                    </template>
                                </tooltip>
                                </template>
                            </div>
							<div class="sum">
                                {{ salary.sum }} <span class="rouble">{{ $store.getters['options/setRouble'] }}</span>
                            </div>
                            <div class="give">
                                <a href="javascript:void(0);" v-if="salary.completed" @click="openGivenSalary(month, salary)">
                                    Выдано<br>
                                    {{ salary.completed }}
                                </a>
                                <div class="text-color-grey text-right text-size-xs" v-else-if="salary.denied">
                                    Недоступно<br>
                                    до
                                    {{salary.denied}}
                                </div>
                                <div class="text-color-grey text-right text-size-xs" v-else-if="salary.owner">
                                    Ожидает выдачи
                                </div>
                                <div class="btn" v-else-if="!salary.completed" @click="openGiveSalary(month, salary)">
                                    выдать
                                </div>
                            </div>
                        </div>
                        <div v-else class="salaries__month-item" v-for="salary in month.rows">
                            <div class="member-block">
                                <div class="member" :class="salary.user.char">
                                    {{ salary.user.char }}{{ salary.user.personalNum?salary.user.personalNum:'' }}
                                </div>
                                <div class="txt">
                                    {{ salary.user.firstName }}
                                    {{ salary.user.lastName }}
                                    <template v-if="salary.calcSum">
                                        ({{salary.calcSum}} <span class="rouble">{{ $store.getters['options/setRouble'] }}</span>)
                                    </template>

                                </div>
                            </div>
                            <div class="objects">
                                {{ salary.flats }}
                            </div>
                            <div class="square-meters">
                                {{ salary.area }} м<sup>2</sup>
                            </div>
                            <div class="official-sum" v-if="user.groupCode != 'B'">
                                <template v-if="salary.official">
                                {{ salary.official.sum }} <span class="rouble">{{ $store.getters['options/setRouble'] }}</span>
								<tooltip v-if="salary.official.completedDate"
				                    :direction="'bottom-center-direction'"
				                    :is-btn-close="false"
				                    :isChangeablePos="false"
									:add-class-wrap="'paid-tooltip'"
				                    :add-style="{width: 264 + 'px'}">
				                    <template v-slot:tooltipBtn>
										<span class="i i-ok7 i--green"></span>
				                    </template>

				                    <template v-slot:tooltipContent>
										<div class="text-size-m">
											{{salary.official.completedComment}}
										</div>
										<div class="text-size-s text-color-grey mt8">
											{{salary.official.completedDate}}
										</div>
										<div class="text-size-s text-color-grey mt4">
											{{salary.official.completedUser.name}}
										</div>
										<div class="text-size-s text-color-grey">
											{{salary.official.completedUser.post}}
										</div>
				                    </template>

				                </tooltip>
                                </template>
                            </div>
                            <div class="sum">
                                {{ salary.sum }} <span class="rouble">{{ $store.getters['options/setRouble'] }}</span>
                            </div>

                            <div class="give">
                                <a href="javascript:void(0);" v-if="salary.completed" @click="openGivenSalary(month, salary)">
                                    Выдано<br>
                                    {{ salary.completed }}
                                </a>
                                <div class="text-color-grey text-center text-size-xs" v-else-if="salary.denied">
                                    Недоступно<br>
                                    до
                                    {{salary.denied}}
                                </div>
                                <div class="text-color-grey text-center text-size-xs" v-else-if="salary.owner">
                                    Ожидает выдачи
                                </div>
                                <div class="btn" v-else-if="!salary.completed" @click="openGiveSalary(month, salary)">
                                    выдать
                                </div>
                            </div>
                        </div>

						<!-- Продажи ремонтов START -->
						<div class="" v-if="month.repair && Object.keys(month.repair).length > 0">
							<div class="salaries__inner-header mt40">
								Продажи ремонтов
							</div>
							<div v-if="loadRows[month.tableCode]" class="salaries__month-list">
	                            <div class="salaries__month-item" v-for="i in 1">
	                                <span>Загрузка...</span>
	                                <span></span>
	                                <span></span>
	                                <span></span>
	                                <span></span>
	                                <span></span>
	                            </div>
	                        </div>
	                        <div v-else class="salaries__month-item" v-for="(salary, index) in month.repair" :key="'sr'+index">
	                            <div class="member-block">
	                                <div class="member" :class="salary.user.char">
	                                    {{ salary.user.char }}{{ salary.user.personalNum }}
	                                </div>
	                                <div class="txt">
	                                    {{ salary.user.firstName }}
	                                    {{ salary.user.lastName }}
	                                </div>
	                            </div>
	                            <div class="objects">
	                                {{ salary.flats }}
	                            </div>
	                            <div class="square-meters">
	                                {{ salary.area }} м<sup>2</sup>
	                            </div>
	                            <div class="official-sum" v-if="user.groupCode != 'B'">
	                            </div>
								<div class="sum">
	                                {{ salary.sum }} <span class="rouble">{{ $store.getters['options/setRouble'] }}</span>
	                            </div>
	                            <div class="give">
	                                <a href="javascript:void(0);" v-if="salary.completed" @click="openGivenReward(month, salary)">
	                                    Выдано<br>
	                                    {{ salary.completed }}
	                                </a>
	                                <div class="text-color-grey text-center text-size-xs" v-else-if="salary.denied">
	                                    Недоступно<br>
	                                    до
	                                    {{salary.denied}}
	                                </div>
	                                <div class="text-color-grey text-center text-size-xs" v-else-if="salary.owner">
	                                    Ожидает выдачи
	                                </div>
	                                <div class="btn" v-else-if="!salary.completed" @click="openGiveReward(month, salary)">
	                                    выдать
	                                </div>
	                            </div>
	                        </div>
						</div>
						<!-- ПРОДАЖИ РЕМОНТОВ END-->
                    </div>
                </div>
            </div>
        </section>
        <popup :show="popups.giveSalary.show"
               :width="540"
               :name="popups.giveSalary.name">
            <give-salary @givedSalary="fetchData()" :salary="modalData.salary" :month="modalData.month"/>
        </popup>
        <popup :show="popups.givenSalary.show"
               :width="540"
               :name="popups.givenSalary.name">
            <given-salary :salary="modalData.salary" :month="modalData.month"/>
        </popup>
        <popup :show="popups.givenReward.show"
               :width="540"
               :name="popups.givenReward.name">
            <given-reward :salary="modalData.salary" :month="modalData.month"/>
        </popup>

		<popup :show="popups.giveReward.show"
               :width="540"
               :name="popups.giveReward.name">
            <give-reward @givedSalary="fetchData()" :salary="modalData.salary" :month="modalData.month"/>
        </popup>

    </div>
</template>
<script>
import {mapState} from 'vuex';
import Tooltip from '@/components/Tooltip.vue';
import Popup from '@/components/modal/Popup.vue';
import GiveSalary from '@/components/modal/salaries/GiveSalary.vue';
import GiveReward from '@/components/modal/salaries/GiveReward.vue';
import GivenSalary from '@/components/modal/salaries/GivenSalary.vue';
import GivenReward from '@/components/modal/salaries/GivenReward.vue';
import NothingBlock from '@/components/NothingBlock.vue';
import {number_format} from "@/helpers/formatted";

export default {
    name: "Salary",
    components: {
        Tooltip,
        Popup,
        GiveSalary,
        GivenSalary,
        GivenReward,
        NothingBlock,
        GiveReward,
    },
    data: function () {
        return {
            openedRows: {},
            modalData: {},
            loadRows: {},
            archRows: {},
        }
    },
    computed: {
        ...mapState({
            popups: state => state.popups.items,
            salaries: state => state.salary.items,
            load: state => state.salary.load,
            itemSecondActive: state => state.menus.itemSecondActive,
            user: state => state.auth.user,
        }),

        pageTitle() {
            let activeItem = this.itemSecondActive;

            return activeItem.title;
        },
        pageDesc() {
            let activeItem = this.itemSecondActive;
            return activeItem.description;
        },
        pageCode() {
            return this.itemSecondActive.code;
        },
    },
    beforeUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    },
    mounted() {
        window.addEventListener('scroll', this.handleScroll);
        this.$nextTick(() => {
            setTimeout(() => {
                this.fetchData();
            }, 1000);
        });
    },
    methods: {
        number_format: function (v) {
            return number_format(v, 2, '.', ' ')
        },
        open(month) {
            if (this.pageCode === 'archive') {
                this.openedRows[month] = !this.openedRows[month];
                //this.loadRows[month] = true;
                //this.openArch(month);
            } else {
                this.openedRows[month] = true;
            }
        },
        openArch(month) {
            this.$nextTick(() => {

                /*if (this.openedRows[month]) {
                    this.$store.dispatch('salary/getMonth', {month: month}).then((response) => {
                        this.archRows[month] = response.items[0].rows;
                        setTimeout(() => {
                            this.loadRows[month] = false;
                        }, 1000);
                    });
                } else {
                    this.loadRows[month] = false;
                }*/
                this.loadRows[month] = false;
            });
        },
        fetchData() {
            let scrollTopBefore = this.getScrollTop();
            this.openedRows = {};
            this.loadRows = {};

            this.$nextTick(() => {
                this.$store.dispatch('salary/getList', {type: this.pageCode}).then(() => {
                    this.setScrollTop(scrollTopBefore)
                });
            });
        },
        openGiveSalary(month, salary) {
            this.modalData = {month: month, salary: salary};
            this.$store.dispatch('popups/open', {name: this.popups.giveSalary.name});
        },
        openGivenSalary(month, salary) {
            this.modalData = {month: month, salary: salary};
            this.$store.dispatch('popups/open', {name: this.popups.givenSalary.name});
        },
        openGiveReward(month, salary) {
            this.modalData = {month: month, salary: salary};
            this.$store.dispatch('popups/open', {name: this.popups.giveReward.name});
        },
        openGivenReward(month, salary) {
            this.modalData = {month: month, salary: salary};
            this.$store.dispatch('popups/open', {name: this.popups.givenReward.name});
        },
        getScrollTop: function () {
            let scroll = window.scrollY;
            return scroll;
        },
        setScrollTop: function (value) {
            window.scrollTo(0, value);
        },
    },
    watch: {
        '$route': 'fetchData'
    },
}
</script>

<style>
.member.d {
    background-color: #FFC700;
}
</style>
