<template>
    <div>
        <div class="popup__header">
            <div class="title">Вознаграждение выдано {{ salary.completed }}</div>
            <div class="text-color-white-trans text-bold mt4">{{ salary.user.post }}: {{ salary.user.firstName }} {{ salary.user.lastName }} ({{ salary.user.char.toUpperCase() }}{{salary.user.personalNum}})</div>
        </div>

        <div class="popup__content give-salary">
            <div class="give-salary__list">
                <div class="give-salary__item" v-for="request in requests">
                    <div class="name">
                        {{ request.name }}
                    </div>
                    <div class="square-meters">
                        {{ request.area }} м<sup>2</sup>
                    </div>
                    <div class="sum">
                        {{ number_format(request.sum) }} <span class="rouble">{{ $store.getters['options/setRouble'] }}</span>
                    </div>
                </div>
                <div class="total text-bold">
                    <div class="df df-space-between mt8">
                        <div class="txt">
                            ИТОГО:
                        </div>
                        <div class="square-meters">
                            {{ allArea }} м<sup>2</sup>
                        </div>
                        <div class="sum">
                            {{ allSum }} <span class="rouble">{{ $store.getters['options/setRouble'] }}</span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="text-color-grey text-size-xs text-uppercase mt40">
                Обстоятельства передачи денег
            </div>
            <div style="margin-top: 9px">
                {{ comment }}
            </div>
            <div class="text-color-grey" style="margin-top: 9px">
                {{ giveUser }},  {{givePost}}
            </div>
        </div>
    </div>
</template>

<script>
    import {mapState} from 'vuex'
    import Tooltip from '@/components/Tooltip.vue';
    import SubmitBtn from '@/components/forms/SubmitBtn.vue';
    import InputStyled from '@/components/forms/InputStyled.vue';
    import {number_format} from "@/helpers/formatted";
    export default {
        name: "GivenReward",
        components: {
            Tooltip,
            SubmitBtn,
            InputStyled,
        },
        props: {
            salary: {
                default() {
                    return 0
                }
            },
            month: {
                default() {
                    return 0
                }
            },
        },
        methods: {
            number_format: function (v) {
                return number_format(v, 0, '', ' ')
            },
            removeError(form) {
                form.error = false;
            },
            fetchData() {
                this.$nextTick(() => {
                    this.$store.dispatch('salary/getModalData', {type: 'modalGivenReward', user: this.salary.user.id, date: this.salary.date, id: this.salary.id}).then((response) => {
                        this.requests = response.result.salary.requests;
                        this.allArea = response.result.salary.allArea;
                        this.allSum = response.result.salary.allSum;
                        this.comment = response.result.salary.comment;
                        this.giveUser = response.result.salary.giveUser;
                        this.givePost = response.result.salary.givePost;
                    });
                });
            },
        },
        data: function () {
            return {
                form: {
                    btnType: 'normal',
                    text1: {
                        value: '',
                        error: false,
                        repeatError: false,
                    },
                },
                requests: {},
                allSum: 0,
                allArea: 0,
                comment: '',
                giveUser: '',
                givePost: '',
            }
        },
        mounted() {
            this.$nextTick(() => {
                setTimeout(() => {
                    this.fetchData();
                }, 500);
            });
        },
        computed: {
            ...mapState({
                popups: state => state.popups.items,
                options: state => state.options,
                loadModalBtnLoad: state => state.loadModalBtnLoad,
            }),
        },
    }
</script>

<style scoped>

</style>
